@charset 'UTF-8';
@import "/Users/fernandosouza/sites/onca-nagoya-patent/src/scss/utility/_media-queries.scss";
@import "/Users/fernandosouza/sites/onca-nagoya-patent/src/scss/utility/_mixin.scss";


.main-banner {
  height: 100vh;
  background: url($img_url + 'top/main_banner.jpg') center center no-repeat;
  background-size: cover;
  position: relative;
  margin-bottom: 120px;
  @include SP {
    background-image: url($img_url + 'top/main_banner_sp.jpg');
    height: 500px;
    margin-bottom: 80px;
  }
  .content-banner {
    text-align: center;
    position: absolute;
    width: 100%;
    top: 54%;
    transform: translate(0, -50%);
    @include SP {
      transform: unset;
      text-align: left;
      top: 61%;
      padding-left: 31px;
    }
    h2 {
      @include ffYM;
      @include font-size(60);
      color: #fff;
      letter-spacing: 23px;
      margin-bottom: 20px;
      @include SP {
        @include font-size(40);
        line-height: 60px;
        letter-spacing: 16px;
      }
    }
    h4 {
      @include ffYMon;
      color: #fff;
      @include font-size(16);
      text-transform: uppercase;
      letter-spacing: 3.2px;
      @include SP {
        @include font-size(11);
        letter-spacing: 2.2px;
      }
    }
  }
  .scroll-next {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 1000;
    @include font-size(12);
    font-weight: bold;
    @include ffYMon;
    font-weight: 500;
    color: #fff;
    letter-spacing: 1px;
    height: 100px;
    letter-spacing: 2px;
    span {
      display: block;
      margin-top: -28px;
    }
    &:before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 50%;
      margin-left: -1px;
      height: 0;
      width: 1px;
      background-color: #fff;
      -webkit-animation: scroll 2s cubic-bezier(1,0,0,1) 1s infinite;
      animation: scroll 2s cubic-bezier(1,0,0,1) 1s infinite;
      -webkit-animation-fill-mode: both;
      animation-fill-mode: both;
    }
  }
}
.two-col {
  .title-content {
    @include ffYM;
    @include font-size(24);
    line-height: 54px;
    margin-bottom: 32px;
    letter-spacing: 3.5px;
    @include ipad {
      @include font-size(20);
      letter-spacing: 2.7px;
    }
    @include SP {
      @include font-size(18);
      line-height: 36px;
      letter-spacing: 2.7px;
      margin-bottom: 25px;
    }
  }
  .desc {
    @include font-size(15);
    line-height: 32px;
    margin-bottom: 53px;
    @include SP {
      line-height: 28px;
      letter-spacing: 0.7px;
      margin-bottom: 33px;
    }
  }
  .vertical-txt {
    @include ffYMon;
    @include font-size(15);
    color: #B8BAC1;
    letter-spacing: 15px;
    text-transform: uppercase;
    writing-mode: vertical-rl;
    text-orientation: mixed;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    @include SP {
      @include font-size(12);
      letter-spacing: 12px;
    }
    span {
      background: #fff;
      position: relative;
      padding-bottom: 8px;
    }
    &:before {
      position: absolute;
      content: '';
      width: 1px;
      height: 100%;
      background: #B8BAC1;
      right: 55%;
      top: 0;
    }
  }
}
.section-about {
  padding: 0 50px 0 100px;
  margin-bottom: 110px;
  @include SP {
    padding: 0 25px;
    margin-bottom: 80px;
  }
  .content-section {
    max-width: 1130px;
    margin: 0 auto;
    @include font-size(0);
    .content-left {
      display: inline-block;
      vertical-align: middle;
      width: 46%;
      padding-right: 80px;
      margin-top: -14px;
      @include SP {
        display: block;
        width: 100%;
        padding-right: 30px;
        margin-top: 0;
        position: relative;
        .img-about-sp {
          margin-bottom: 31px;
        }
      }
    }
    .content-right {
      display: inline-block;
      vertical-align: middle;
      width: 54%;
      position: relative;
      padding-right: 50px;
    }
  }
}
.section-profess {
  padding: 0 100px 0 50px;
  margin-bottom: 120px;
  @include SP {
    padding: 0 25px;
    margin-bottom: 80px;
  }
  .content-section {
    max-width: 1130px;
    @include font-size(0);
    margin: 0 auto;
    .vertical-txt {
      left: 0;
      right: auto;
    }
    .content-left {
      display: inline-block;
      vertical-align: middle;
      width: 54%;
      position: relative;
      padding-left: 50px;
      @include SP {
        display: block;
        width: 100%;
        padding-left: 30px;
      }
    }
    .content-right {
      display: inline-block;
      vertical-align: middle;
      width: 46%;
      padding-left: 80px;
      margin-top: -14px;
      @include SP {
        display: block;
        width: 100%;
        margin-top: 0;
        padding-left: 30px;
        position: relative;
        .img-profess-sp {
          margin-bottom: 31px;
        }
      }
    }
  }
}
.section-services {
  padding: 90px 0;
  background: #F4F4F4;
  @include SP {
    padding: 60px 0;
  }
  .cm-ttl01 {
    margin-bottom: 60px;
    @include SP {
      margin-bottom: 40px;
    }
    .ttl-en {
      @include font-size(40);
      @include SP {
        @include font-size(26);
      }
    }
  }
  .cm-list-service {
    margin-bottom: 11px;
    @include SP {
      margin-bottom: 20px;
    }
  }
}
.section-provide {
  // padding: 0 20px 0;
  // max-width: 1240px;
  margin: 0 20px;
  @include SP {
    padding: 0 10px 0;
    margin: 0;
  }
  .content-section {
    background: url($img_url + 'top/bg_provide.jpg') bottom center no-repeat;
    background-size: 100% auto;
    padding: 120px 20px 100px;
    @include SP {
      padding: 60px 15px 60px;
      background-image: url($img_url + 'top/bg_provide_sp.jpg');
    }
    .detail {
      max-width: 1000px;
      margin: 0 auto;
      .detail-left {
        float: left;
        width: 43%;
        margin-right: 8%;
        margin-top: 40px;
        @include SP {
          float: none;
          width: 100%;
          margin-right: 0;
          margin-top: 0;
          margin-bottom: 50px;
        }
        .cm-ttl01 {
          margin-bottom: 51px;
          @include SP {
            margin-bottom: 34px;
          }
        }
        .desc {
          @include font-size(15);
          line-height: 32px;
          letter-spacing: 0.8px;
          margin-bottom: 51px;
          @include SP {
            line-height: 28px;
            margin-bottom: 33px;
          }
        }
      }
      .detail-right {
        float: right;
        width: 49%;
        @include SP {
          float: none;
          width: 100%;
        }
        ul {
          li {
            margin-bottom: 20px;
            @include SP {
              margin-bottom: 6px;
            }
            &:last-child {
              margin-bottom: 0;
            }
            a {
              display: block;
              @include font-size(0);
              padding: 9px 20px;
              background: #fff;
              width: 100%;
              @include add_prefix(box-shadow, 0px 0px 12px rgba(21,24,30,0.1));
              position: relative;
              cursor: pointer;
              background-image: transparent;
              &:before {
                position: absolute;
                content: '';
                height: 100%;
                width: 36px;
                right: 0;
                top: 0;
                z-index: 0;
                background-image: linear-gradient(45deg, #1D53C3, #ABC5E8);
              }
              &:after {
                content: "";
                position: absolute;
                right: 16px;
                top: 41%;
                width: 8px;
                height: 8px;
                border-left: 1px solid #fff;
                border-top: 1px solid #fff;
                -moz-transform: rotate(135deg);
                -webkit-transform: rotate(135deg);
                -ms-transform: rotate(135deg);
                transform: rotate(135deg);
                z-index: 2;
              }
              @include SP {
                padding: 12.5px 50px 12.5px 15px;
              }
              @include ipad {
                padding-right: 55px;
              }
              @include HOVER {
                &:hover {
                  opacity: 1;
                  &:before {
                    // opacity: 0;
                  }
                  span, strong {
                    color: #fff;
                  }
                  .bg-hover {
                    opacity: 1;
                  }
                }
              }
            }

            .bg-hover {
              transition: all 0.3s;
              background-image: linear-gradient(45deg, #1D53C3, #ABC5E8);
              z-index: 0;
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              opacity: 0;
            }

            span {
              display: inline-block;
              vertical-align: middle;
              @include font-size(20);
              @include ffYMon;
              color: #1B307A;
              font-weight: 500;
              width: 55px;
              position: relative;
              z-index: 1;
              &:before {
                position: absolute;
                content: '';
                width: 1px;
                height: 16px;
                background: #ABC5E8;
                transform: rotate(32deg);
                right: 15px;
                top: 8px;
                @include SP {
                  top: 4px;
                }
              }
              @include SP {
                @include font-size(16);
                width: 50px;
              }
            }
            strong {
              display: inline-block;
              vertical-align: middle;
              font-weight: normal;
              @include ffYM;
              @include font-size(18)
              color: #1B307A;
              width: calc(100% - 55px);
              position: relative;
              // margin-top: 2px;
              letter-spacing: 1.7px;
              z-index: 1;
              @include ipad {
                @include font-size(16);
              }
              @include SP {
                @include font-size(16);
                line-height: 22px;
                width: calc(100% - 50px);
              }
            }
          }
        }
      }
    }
  }
}
.section-news {
  padding: 97px 15px;
  @include SP {
    padding: 57px 25px 50px;
  }
  .cm-ttl02 {
    margin-bottom: 63px;
    @include SP {
      margin-bottom: 27px;
    }
    span {
      @include ffYM;
      @include font-size(16);
      display: inline-block;
      vertical-align: middle;
      margin-left: 15px;
      letter-spacing: 1.5px;
      color: #15181F;
    }
  }
  .content-section {
    max-width: 1180px;
    margin: 0 auto;
  }
  .list-news {
    .slick-slide {
      &:first-child {
        li {
          border-left: 1px solid #E6E6E6;
        }
      }
      &.slick-current {
        li {
          border-left: 1px solid #E6E6E6;
        }
      }
    }
    li {
      border-right: 1px solid #E6E6E6;
      overflow: hidden;
      @include SP {
        border-right: 0;
        border-top: 1px solid #E6E6E6;
        &:last-child {
          border-bottom: 1px solid #E6E6E6;
        }
      }
      a {
        display: block;
        padding: 0 40px;
        @include ipad {
          padding: 0 20px;
        }
        @include SP {
          padding: 17px 0;
          @include font-size(0);
        }
      }
      .date-cat {
        @include font-size(0);
        margin-bottom: 15px;
        overflow: hidden;
        white-space: nowrap;
        @include SP {
          margin-bottom: 10px;
        }
        .date {
          display: inline-block;
          vertical-align: middle;
          @include font-size(15);
          font-weight: 500;
          color: #1B307A;
          @include ffYMon;
          margin-right: 12px;
          letter-spacing: 0.6px;
          @include SP {
            @include font-size(13);
            margin-right: 20px;
          }
        }
        .cat {
          display: inline-block;
          vertical-align: middle;
          strong {
            @include font-size(13);
            color: #1B307A;
            padding: 2px 15px 0;
            background: #F4F4F4;
            display: inline-block;
            vertical-align: middle;
            margin-right: 10px;
            font-weight: 500;
            &:last-child {
              margin-right: 0;
            }
            @include SP {
              @include font-size(12);
              padding: 1px 15px;
            }
          }
        }
      }
      .thumb-new {
        margin-bottom: 20px;
        @include SP {
          display: inline-block;
          vertical-align: top;
          width: 104px;
          height: 78px;
          margin-bottom: 0;
        }
      }
      .content-new {
        @include SP {
          display: inline-block;
          vertical-align: top;
          width: calc(100% - 104px);
          padding-left: 20px;
        }
      }
      .news-title {
        @include ffYM;
        @include font-size(15);
        line-height: 28px;
        letter-spacing: 0.7px;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        max-height: 78px;
        overflow: hidden;
        @include SP {
          line-height: 24px;
          max-height: auto;
          -webkit-line-clamp: 2;
        }
      }
    }
  }
  .slick-disabled {
    pointer-events: none;
    opacity: 0.5;
  }
  .slick-dots {
    position: absolute;
    right: 150px;
    top: -96px;
    display: inline-block;
    overflow: hidden;
    span {
      position: absolute;
      content: '';
      background: #1B307A;
      width: 0;
      height: 1px;
      left: 0;
      top: 51%;
      transition: all 1s;
    }
    li {
      display: inline-block;
      vertical-align: middle;
      margin-right: -1px;
      &.slick-active {
        button {
          background: #E6E6E6;
        }
      }
      button {
        display: block;
        font-size: 0;
        border: 0;
        width: 60px;
        background: #E6E6E6;
        padding: 0;
        margin-right: 0;
        height: 1px;
      }
    }
  }
  .slick-arrow {
    position: absolute;
    top: -109px;
    @include font-size(0);
    width: 50px;
    height: 50px;
    @include border-radius(50%);
    border: solid 1px #E6E6E6;
    background: #fff;
    transition: all 0.3s;
    &:hover {
      background-image: linear-gradient(45deg, #1D53C3, #ABC5E8);
      border: 0;
      &:before {
        border-left: 1px solid #FFFFFF;
        border-top: 1px solid #FFFFFF;
      }
    }
  }
  .slick-prev {
    right: 60px;
    &:before {
      content: "";
      position: absolute;
      left: 22px;
      top: 40%;
      width: 10px;
      height: 10px;
      border-left: 1px solid #1B307A;
      border-top: 1px solid #1B307A;
      -moz-transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }
  }
  .slick-next {
    right: 0;
    &:before {
      content: "";
      position: absolute;
      left: 16px;
      top: 40%;
      width: 10px;
      height: 10px;
      border-left: 1px solid #1B307A;
      border-top: 1px solid #1B307A;
      -moz-transform: rotate(135deg);
      -webkit-transform: rotate(135deg);
      -ms-transform: rotate(135deg);
      transform: rotate(135deg);
    }
  }
  .list-btn {
    margin-top: 52px;
    @include font-size(0);
    text-align: center;
    @include SP {
      margin-top: 40px;
    }
    p {
      display: inline-block;
      vertical-align: middle;
      max-width: 320px;
      width: 100%;
      margin-right: 20px;
      @include SP {
        display: block;
        max-width: 265px;
        margin: 0 auto 10px!important;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
}

@-webkit-keyframes scroll {
    0% {
        bottom: auto;
        top: 0;
        height: 0
    }
    50% {
        height: 100px
    }
    100% {
        top: auto;
        bottom: 0;
        height: 0
    }
}
@-moz-keyframes scroll {
    0% {
        bottom: auto;
        top: 0;
        height: 0
    }
    50% {
        height: 100px
    }
    100% {
        top: auto;
        bottom: 0;
        height: 0
    }
}
@-ms-keyframes scroll {
    0% {
        bottom: auto;
        top: 0;
        height: 0
    }
    50% {
        height: 100px
    }
    100% {
        top: auto;
        bottom: 0;
        height: 0
    }
}
@keyframes scroll {
    0% {
        bottom: auto;
        top: 0;
        height: 0
    }
    50% {
        height: 100px
    }
    100% {
        top: auto;
        bottom: 0;
        height: 0
    }
}